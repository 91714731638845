<template>
    <div class="main">
        <div class="home consent-page">
            <div id="dne"></div>
            <Header />

            <div id="consent">
                <div class="load" v-if="load"></div>

                <section class="consent" v-if="showContent">
                    <div class="container">
                        <div class="consent-cont">
                            <div class="row">
                                <div class="col-12">
                                    <h2 class="title-primary">{{getMyLanguage("permission","consent.title")}}</h2>
                                    <p v-html='getMyLanguage("permission","consent.text-01")'></p>
                                    <p v-html='getMyLanguage("permission","consent.text-02")'></p>

                                    <div class="bts-privacy-terms">
                                        <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}/privacy`" class="lnk-terms" target="_blank">{{getMyLanguage("permission","consent.lnk-privacy")}}</a>
                                        <a :href="`${VUE_APP_INTERNATIONAL_URL}${flagSelected}/terms-and-conditions`" class="lnk-terms" target="_blank">{{getMyLanguage("permission","consent.lnk-terms")}}</a>
                                    </div>
                                </div>

                                <div class="col-12">
                                    
                                    <!-- LOGIN -->
                                    <label class="lbl-pass-consent">{{getMyLanguage("permission","consent.lbl.password")}}</label>
                                    <div class="enterPass">
                                        <input type="password" class="form formPassLogin" v-if="!showPass" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>
                                        <input type="text" class="form formPassLogin" v-if="showPass" :placeholder='getMyLanguage("login","login.placeholder.password")' v-model="sign.password" autocomplete="off" required>

                                        <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                        <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                                    </div>
                                    
                                    <div class="bts">
                                        <div class="btn-primary" @click="loginConsent()" v-if="!stepLogin.load && sign.password.length >= 8">{{getMyLanguage("permission","consent.btn-authorize")}}</div>
                                        <div class="btn-primary btn-disabled" v-if="sign.password.length < 8">{{getMyLanguage("permission","consent.btn-authorize")}}</div>
                                        <div class="btn-primary btn-loading" v-if="stepLogin.load">{{getMyLanguage("permission","consent.btn-authorize")}}</div>

                                        <!-- <div class="btn-cancel-consent" @click="noAuthorize()" v-if="!stepLogin.load">{{getMyLanguage("permission","consent.btn-notauthorize")}}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <!-- BOX ERROR -->
                <!-- <div class="box-error" v-if="boxError">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error","expire_login") }}</p>
                    <div class="cod-error">
                        Cód.: {{errorsGet.dataAccount}}
                    </div>
                </div> -->
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiPlatform from '@/config/apiPlatform.js'
  
    export default {
        components: {
            Header,
            Footer
        },
        
        data(){
            return{
                flagSelected: "en",
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                showPass: false,
                load: true,
                showContent: false,
                // boxError: false,
                // dataAccount: [],

                // errorsGet: {
                //     dataAccount: false
                // },

                stepLogin: {
                    'load': false
                },

                sign: {
                    "scope": "",
                    "password": ""
                },

                tk: "",
                newtk: "",
                destiny: ""
            }
        },
        
        async mounted(){
            // const tokenValue = localStorage.getItem('accessToken')
            // console.log(tokenValue)

            const urlParams = new URLSearchParams(window.location.search);
            this.tk = urlParams.get('tk')
            this.sign.scope = urlParams.get('scope')
            this.destiny = urlParams.get('destiny');
            
            // console.log(this.tk)

            localStorage.removeItem('accessToken');
            localStorage.setItem('accessToken',this.tk);

            this.load = false
            this.showContent = true
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code

                document.title = this.getMyLanguage("seo","title.consent");
                document.getElementsByTagName('meta')["description"].content = ''
            },

            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },
            
            loginConsent(){
                this.stepLogin.load = true
                const loginConsent = this.sign;

                apiPlatform.post("/api/v1/platform/consent/ask", loginConsent)
                .then(response => {
                    const responseData = response.data.accessToken
                    
                    localStorage.removeItem('accessToken');
                    localStorage.setItem('accessToken',responseData);
                    this.newtk = localStorage.getItem('accessToken')

                    setTimeout(() => {
                        window.location.href = `${this.VUE_APP_INTERNATIONAL_URL}${this.flagSelected}/account/token-auth?tk=${this.newtk}&destiny=${this.destiny}`
                    }, 100);
                })
                .catch(error => {
                    this.stepLogin.load = false

                    this.errorMessage = error.message;

                    // this.$toast.error(this.getMyLanguage("permission","error-password" + this.stepLogin.errorMessage));
                    this.$toast.error(this.getMyLanguage("permission","consent.error-password"));
                    setTimeout(this.$toast.clear, 6000)
                })
            },


            // loginConsent(){
            //     this.stepLogin.load = true
            //     const loginConsent = this.sign;

            //     apiPlatform.post("/api/v1/platform/consent/ask", loginConsent)
            //     .then(response => {
            //         const responseData = response.data.accessToken

            //         localStorage.removeItem('accessToken');
            //         localStorage.setItem('accessToken',responseData);

            //         window.location.href = this.destiny
            //     })
            //     .catch(error => {
            //         this.stepLogin.load = false

            //         this.errorMessage = error.message;

            //         // this.$toast.error(this.getMyLanguage("permission","error-password" + this.stepLogin.errorMessage));
            //         this.$toast.error(this.getMyLanguage("permission","consent.error-password"));
            //         setTimeout(this.$toast.clear, 6000)
            //     })
            // },


            noAuthorize(){
                window.location.href = `/${this.flagSelected}/client-space/`
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },
        
        created () {
            document.title = this.getMyLanguage("seo","title.consent");
            document.getElementsByTagName('meta')["description"].content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
    }
</script>

<style src="./custom-consent.scss" lang="scss" scoped />
<style src="./custom-login.scss" lang="scss" scoped />